/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          // responsive toggler
          var toggler = $(".responsive-toggle, .burger");
          // sidebar
          var sidebar = $("aside.sidebar");
          // body
          var body = $("body");
          // compilance search form
          var compliantSearch = $('.sidebar .search-form');

          var sidebarDisplayed = true;
          var clicked = false;
          function toggleSidebar(caller){
              // catch ghostclicks
              if(clicked) {
                  return;
              }
              clicked = true;
              sidebar.toggleClass("hidden showed");
              // add classes to body
              body.toggleClass("sidebar-hidden sidebar-showed");
              sidebarDisplayed = !sidebarDisplayed;

              setTimeout(function() {
                  clicked = false;
                  $('[data-spy="affix"]').trigger('affixed.bs.affix');
              }, 400);
          }
          // handle sidebar on resize and init
          var home = window.location.pathname === "/";
          $(window).resize(function(){
              // control the sidebar
              if($(window).width() < 990 && sidebarDisplayed && !home){
                  toggleSidebar();
              }
              else if(($(window).width() > 990 && !sidebarDisplayed) || (home && !sidebarDisplayed)){
                  toggleSidebar();
              }
              // control the affix width
          });

          // on init
          if($(window).width() < 990 && !home){
              toggleSidebar();
          }

          // change character on close
          toggler.click(function(){
            toggleSidebar();
          });

          // Stick the responsive toggler
          sidebar.scroll(function(){
            var scrollTop = ($(this).height() / 2) + $(this).scrollTop() + 15;
            toggler.css({'top':scrollTop});
          });

          // affix general
          // use the with of the parent item
          $('[data-spy="affix"]')
              .on('affixed.bs.affix', function () {
                  // from http://stackoverflow.com/questions/6551429/adjust-a-width-based-on-parent-w-jquery
                  $(this).css("width", $(this).parent().css("width").replace('px','') );
              })
              .on('affixed.bs.affix-top', function () {
                  // from http://stackoverflow.com/questions/6551429/adjust-a-width-based-on-parent-w-jquery
                  $(this).css("width",'auto');
              });

          // set affix for the main-sidebar
          $(".main-sidebar-container").affix({
                  offset: {
                      top: function () {
                          return $('.main-slider').outerHeight(true);
                      },
                      bottom: 1
                  }
              }
          );

          // set affix for complaint search
          compliantSearch.affix({
              offset: {
                  top: function () {
                      return compliantSearch.offset().top;
                  }
              }
          });

          // handle abstract collapse
          $('.abstract > h3').click(function(){
              if($(this).hasClass('open')) {
                  $(this).parent().find("*:not(h3)").fadeOut();
                  $(this).removeClass("open");
              }
              else{
                  $(this).parent().find("*:not(h3)").fadeIn();
                  $(this).addClass("open");
              }
          });

          // hide scrollbar
          $(".sidebar-inner").hideScrollBar();

          // resize sidebar on small devices
          var resizeScrollbar =  function() { if($(window).width() < 990 ) {$('.sidebar').height($(window).height() + 60);}};
          $(window).on('resize', resizeScrollbar);
          resizeScrollbar();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
          // trigger scroll for affixes
          $(window).trigger('scroll');
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
