/**
 * Created by tmen on 05.05.17.
 */
/**
 * Created by tmen on 01.04.16.
 */
(function ($) {

    "use strict";

    /**
     * Options for the plugin
     */
    var settings;

    /**
     * Calculated width of the scrollbar
     * @type {number}
     */
    var scrollBar = 15;

    /**
     * Calculate the width of a scrollbar. Add two nested divs to the body and calc the difference
     * @returns {number}
     */
    function scrollBarWidth() {
        var div = $('<div class="antiscroll-inner" style="width:50px;height:50px;overflow-y:scroll;position:absolute;top:-200px;left:-200px;"><div style="height:100px;width:100%"/></div>');
        $('body').append(div);
        var w1 = $(div).innerWidth();
        var w2 = $('div', div).innerWidth();
        $(div).remove();
        return w1 - w2 || 15;
    }

    /**
     * Hide the scrollBar
     * @param column
     * @returns {void}
     */
    function hideScrollBar(column) {
        // add to body to measure
        var width = parseInt(column.css('padding-right')) || 0;
        width = width + scrollBar;
        var css = { 'width': 'calc(100% + ' + scrollBar + 'px)', 'padding-right':  + width +'px'};
        column.css(css);
    }



    /**
     * all methods of the plugin
     * @type {{init: methods.init}}
     * */
    var methods = {
        init: function (options) {
            settings = $.extend({}, options);
            scrollBar = scrollBarWidth();
            return this.each(function () {
                hideScrollBar($(this));
            });
        }
    };
    /**
     * declare plugin with methods
     * @param methodOrOptions
     * @returns {*}
     */
    $.fn.hideScrollBar = function (methodOrOptions) {
        if (methods[methodOrOptions]) {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof methodOrOptions === 'object' || !methodOrOptions) {
            // Default to "init"
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + methodOrOptions + ' does not exist on jQuery.hideScrollBar');
        }
    };

})(jQuery);