/**
 * Created by tmen on 27.03.17.
 */
(function($) {
    // slider obj
    var slider = {
        // object
        obj: null,
        // items
        items : [],
        // the active item
        activeIndex: [],
        // item containers
        itemContainer: [],
        // index of the container
        containerIndex:0,
        displayItem:function(){
            if(($(window).width() > 1200) && ($("body").hasClass('sidebar-hidden'))){
                return 3;
            }
            return 2;
        },
        setObj: function (obj){
            this.obj = obj;
            this.itemContainer = this.obj.find('.item-container');
            for(var i = 0; this.itemContainer.length > i; i++){
                this.activeIndex[i] = 0;
            }
        },
        next: function (){
            this.active(this.containerIndex);
            this.containerIndex++;
            if(this.containerIndex > this.displayItem() -1) {
                this.containerIndex=0;
            }
        },
        active: function(containerIndex) {
            var container = this.itemContainer[containerIndex];
            var index = this.activeIndex[containerIndex];
            var size = $(container).find('.item').length -1 ;
            // console.log("ci="+containerIndex+": aI="+index+" >= s="+size);
            if(index === size){
                // console.log("rw");
                this.activeIndex[containerIndex] = 0;
                this.rewind($(container));
            }
            else {
                this.activeIndex[containerIndex]++;
                $($(container).find('.item')[size - index]).addClass('transparent');
            }
        },

        rewind: function (container){
            container.find('.item').removeClass('transparent');
        }
    };
    // init
    var sliderObj = $('.main-slider');
    slider.setObj(sliderObj);
    setInterval(function(){
        slider.next();
    },5000);
})(jQuery);

