/**
 * Created by tmen on 26.06.17.
 */
(function($){
    $(document).ready(function($){
        var links = [];
        var ul = $('<ul>');

        function toUnderscore(text){
            return text.replace(/ /g,"_");
        }
        // make hot links for complaints
        $('.single-complaint .main-content strong,.post-type-archive-complaint .main-content strong').each(function(i,d){
            if($(this).text().length > 6){
                // add angkor
                $(this).prop({'id': toUnderscore($(this).text())});
                links.push({id:toUnderscore($(this).text()), text:$(this).text()});
            }
        });

        // make hotlinks for abstracts
        // todo: language
        if($(".abstracts").length > 0){
            $(".abstracts").prop({'id':'zusammenfassung'});
            var li = $('<li>').append($('<a>',{href:'#zusammenfassung'}).text('Zusammenfassung'));
            ul.append(li);
        }

        // build list
        $(links).each(function(){
            var li = $('<li>').append($('<a>',{href:'#'+this.id}).text(this.text));
            ul.append(li);
        });

        $('.single-complaint .main-sidebar .main-sidebar-container').append(ul);
    });
})(jQuery);